

















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Page from '@/components/swine/layout/Page.vue';
import GeneralPage from '@/components/general/layout/GeneralPage.vue';
import InnerPageTemplate from '@/components/swine/layout/InnerPageTemplate.vue';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import { FormStyling } from '@/models/swine/form/form.model';
import {
  ChangePasswordParams,
  UserProfileEdit,
} from '@/models/swine/login/user.model';
import { ProfileStores } from '@/store/swine/profile/index';
import { errorMessages, validatePassword } from '@/utils/validation';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { VuePhoneNumberInputInterface } from '@/models/swine/user/user.model';
import { capitalize } from 'lodash';

@Component({
  components: {
    Page,
    GeneralPage,
    InnerPageTemplate,
    FormTemplate,
    VuePhoneNumberInput,
  },
})
export default class GeneralEditUserProfile extends Vue {
  pageTitle = 'Edit Profile';
  formTitle = 'Change Password';
  modalWidth = 680; // This is used to change the form's width ---> Modify the width of the modal form container.
  /*
        This is to modify the form's height and border-radius property
        To modify the width of the form, we have to modify the b-modal width as shown above
    */
  defaultStyling: FormStyling = {
    height: 'auto',
    borderRadius: '10px',
    width: '680px',
  };
  isPasswordButtonDisabled = true;
  isUpdateProfileButtonDisabled = true;
  showPasswordFormatWarning = false;
  showNewPasswordFormatWarning = false;
  showConfirmNewPasswordFormatWarning = false;
  isNameInvalid = false;
  isPhoneNumberInvalid = false;
  store = ProfileStores.profile;
  userProfile: UserProfileEdit | null = null;
  isChangePasswordFormActive = false;
  updatePasswordDict = {
    currentPassword: '',
    newPassword: '',
    newConfirmedPassword: '',
  };
  passwordNotMatchMessage = errorMessages.PASSWORD_ERROR;
  phoneNumberCountryCodes = [
    'MY',
    'PH',
    'ID',
    'TH',
    'VN',
    'KH',
    'MM',
    'LA',
    'CN',
    'BN',
    'TW'
  ];
  currentCountryPhoneCode = 'MY';
  currentRole = '';
  currentPhoneNumber = '';

  get invalidNameMessage() {
    return 'Name cannot be empty';
  }

  get invalidPhoneNumberMessage() {
    return errorMessages.PHONE_ERROR;
  }

  getCountryPhoneCode(): string {
    if (this.currentPhoneNumber) {
      const phoneCountryCode = this.currentPhoneNumber.substr(0, 3);
      let output = '';
      switch (phoneCountryCode) {
        case '+60': {
          output = 'MY';
          break;
        }
        case '+63': {
          output = 'PH';
          break;
        }
        case '+62': {
          output = 'ID';
          break;
        }
        case '+66': {
          output = 'TH';
          break;
        }
        case '+84': {
          output = 'VN';
          break;
        }
        case '+855': {
          output = 'KH';
          break;
        }
        case '+95': {
          output = 'MM';
          break;
        }
        case '+856': {
          output = 'LA';
          break;
        }
        case '+86': {
          output = 'CN';
          break;
        }
        case '+673': {
          output = 'BN';
          break;
        }
        case '+886': {
          output = 'TW';
          break;
        }
        default: {
          output = 'MY';
          break;
        }
      }
      return output;
    }
    return 'MY';
  }

  passwordErrorMessage(switchError: boolean) {
    if (switchError) {
      return `Confirm new password must have a minimum length of 8 with 1 capital letter, 1 number, match with New Password and must not match with Current Password`;
    }
    return `New password must have a minimum length of 8 with 1 capital letter, 1 number, match with Confirm New Password and must not match with Current Password`;
  }

  created() {
    this.store.getUserProfile();
  }

  @Watch('isChangePasswordFormActive')
  resetForm() {
    if (this.isChangePasswordFormActive === false) {
      this.showPasswordFormatWarning = false;
      this.showNewPasswordFormatWarning = false;
      this.showConfirmNewPasswordFormatWarning = false;
      this.passwordNotMatchMessage = errorMessages.PASSWORD_ERROR;
      this.resetPasswordDictionary();
    }
  }

  @Watch('store.userProfile')
  getUserProfile() {
    if (this.store.userProfile) {
      this.currentRole = capitalize(this.store.userProfile.role);
      this.userProfile = this.store.userProfile;
      this.currentPhoneNumber = this.store.userProfile.phoneNumber;
      this.currentCountryPhoneCode = this.getCountryPhoneCode();
    }
  }

  updateUserProfile() {
    if (!this.userProfile) {
      return;
    }
    this.userProfile.phoneNumber = this.currentPhoneNumber;
    this.store.setUserProfile(this.userProfile);
    this.store.updateUserProfile();
    this.isUpdateProfileButtonDisabled = true;
  }

  validateNameInput() {
    if (this.userProfile && this.userProfile.name.length < 1) {
      this.isNameInvalid = true;
    } else {
      this.isNameInvalid = false;
    }
    this.validateInput();
  }

  validatePhoneNumberInput(params: VuePhoneNumberInputInterface) {
    this.isPhoneNumberInvalid = !params.isValid ? true : false;
    this.currentPhoneNumber = params.formattedNumber;
    this.validateInput();
  }

  validateInput() {
    if (!this.isNameInvalid && !this.isPhoneNumberInvalid) {
      this.isUpdateProfileButtonDisabled = false;
    } else {
      this.isUpdateProfileButtonDisabled = true;
    }
  }

  validateCurrentPassword() {
    const { currentPassword } = this.updatePasswordDict;
    if (!validatePassword(currentPassword)) {
      this.showPasswordFormatWarning = true;
    } else {
      this.showPasswordFormatWarning = false;
    }
    this.checkPasswordFormStatus();
  }

  validateNewPassword() {
    const { currentPassword, newPassword, newConfirmedPassword } =
      this.updatePasswordDict;

    if (!validatePassword(newPassword) || newPassword === currentPassword) {
      this.showNewPasswordFormatWarning = true;
    } else {
      this.showNewPasswordFormatWarning = false;
    }

    if (
      !validatePassword(newConfirmedPassword) ||
      newConfirmedPassword === currentPassword
    ) {
      this.showConfirmNewPasswordFormatWarning = true;
    } else {
      this.showConfirmNewPasswordFormatWarning = false;
    }

    if (newPassword !== newConfirmedPassword) {
      this.showNewPasswordFormatWarning = true;
      this.showConfirmNewPasswordFormatWarning = true;
    }

    this.checkPasswordFormStatus();
  }

  checkPasswordFormStatus() {
    // Check one more time
    const { currentPassword, newPassword, newConfirmedPassword } =
      this.updatePasswordDict;

    if (!validatePassword(newPassword) || newPassword === currentPassword) {
      this.showNewPasswordFormatWarning = true;
    } else {
      this.showNewPasswordFormatWarning = false;
    }

    if (
      !validatePassword(newConfirmedPassword) ||
      newConfirmedPassword === currentPassword
    ) {
      this.showConfirmNewPasswordFormatWarning = true;
    } else {
      this.showConfirmNewPasswordFormatWarning = false;
    }

    if (newPassword !== newConfirmedPassword) {
      this.showNewPasswordFormatWarning = true;
      this.showConfirmNewPasswordFormatWarning = true;
    }

    if (!validatePassword(currentPassword)) {
      this.showPasswordFormatWarning = true;
    } else {
      this.showPasswordFormatWarning = false;
    }

    if (
      !this.showPasswordFormatWarning &&
      !this.showNewPasswordFormatWarning &&
      !this.showConfirmNewPasswordFormatWarning
    ) {
      this.isPasswordButtonDisabled = false;
    } else {
      this.isPasswordButtonDisabled = true;
    }
  }

  async updateUserPassword() {
    const input: ChangePasswordParams = {
      newPassword: this.updatePasswordDict.newPassword,
      currentPassword: this.updatePasswordDict.currentPassword,
    };
    await this.store.updateUserPassword(input);
    this.checkUpdatePasswordProcess();
  }

  checkUpdatePasswordProcess() {
    if (this.store.isUpdatePasswordValid && this.userProfile) {
      this.isChangePasswordFormActive = false;
      this.resetPasswordDictionary();
    } else {
      this.isChangePasswordFormActive = true;
      this.showPasswordFormatWarning = true;
      this.passwordNotMatchMessage = `Password does not match with current password. ${errorMessages.PASSWORD_ERROR}`;
    }
  }

  resetPasswordDictionary() {
    this.updatePasswordDict = {
      currentPassword: '',
      newPassword: '',
      newConfirmedPassword: '',
    };
  }

  closeModal(payload: boolean) {
    this.isChangePasswordFormActive = payload;
  }
}
