import { render, staticRenderFns } from "./GeneralEditUserProfile.vue?vue&type=template&id=36e86d63&scoped=true&"
import script from "./GeneralEditUserProfile.vue?vue&type=script&lang=ts&"
export * from "./GeneralEditUserProfile.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralEditUserProfile.vue?vue&type=style&index=0&id=36e86d63&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e86d63",
  null
  
)

export default component.exports